@media only screen and (min-width: 320px) and (max-width: 767px) {
    .salaJuegos {
        width: 310%;
        position: relative;
        left: 45%;
        transform: translateX(-50%);   
    }
}

*[id$="Shadow"]  {
    filter: blur(2.5px)
}

*[id$="Axis"]   {
    display: none;
}

*[id$="DropShadow"]  {
    filter: blur(1px)
}

.cls-1, .cls-51, .cls-54 {
fill: none;
}

.cls-19, .cls-2, .cls-67 {
    isolation: isolate;
}

.cls-17, .cls-3, .cls-86, .cls-91 {
    fill: #fff;
}

.cls-12, .cls-3 {
    opacity: 0;
}

.cls-16, .cls-30, .cls-4, .cls-53 {
    mix-blend-mode: multiply;
}

.cls-26, .cls-4, .cls-53 {
    opacity: 0.3;
}

.cls-5, .cls-53 {
    fill: #738b92;
}

.cls-6 {
    fill: #43b389;
}

.cls-7 {
    clip-path: url(#clip-path);
}

.cls-8 {
    opacity: 0.27;
}

.cls-9 {
    fill: url(#Degradado_sin_nombre_120);
}

.cls-10 {
    fill: #133327;
}

.cls-11 {
    fill: #2c7359;
}

.cls-13 {
    fill: url(#Degradado_sin_nombre_79);
}

.cls-14 {
    fill: #99b9c2;
}

.cls-15 {
    clip-path: url(#clip-path-2);
}

.cls-16 {
    opacity: 0.58;
}

.cls-18 {
    clip-path: url(#clip-path-3);
}

.cls-19 {
    opacity: 0.52;
}

.cls-20 {
    fill: url(#Degradado_sin_nombre_33);
}

.cls-21 {
    fill: #e7e9ea;
}

.cls-22 {
    fill: url(#Degradado_sin_nombre_154);
}

.cls-23, .cls-91 {
    mix-blend-mode: overlay;
    opacity: 0.5;
}

.cls-24 {
    fill: url(#Degradado_sin_nombre_18);
}

.cls-25 {
    fill: url(#Degradado_sin_nombre_7);
}

.cls-27, .cls-86 {
    mix-blend-mode: screen;
    opacity: 0.14;
}

.cls-28 {
    fill: #03ffda;
}

.cls-29 {
    clip-path: url(#clip-path-4);
}

.cls-30, .cls-67 {
    opacity: 0.2;
}

.cls-31 {
    fill: url(#Degradado_sin_nombre_154-2);
}

.cls-32, .cls-99 {
    fill: #010159;
}

.cls-33 {
    fill: url(#Degradado_sin_nombre_165);
}

.cls-34 {
    fill: url(#Degradado_sin_nombre_79-2);
}

.cls-35 {
    clip-path: url(#clip-path-5);
}

.cls-36 {
    clip-path: url(#clip-path-6);
}

.cls-37 {
    fill: #002e81;
}

.cls-38 {
    fill: url(#Degradado_sin_nombre_33-2);
}

.cls-39 {
    fill: #01012d;
}

.cls-40 {
    fill: url(#Degradado_sin_nombre_3);
}

.cls-41 {
    fill: url(#Degradado_sin_nombre_18-2);
}

.cls-42 {
    fill: url(#Degradado_sin_nombre_24);
}

.cls-43 {
    clip-path: url(#clip-path-7);
}

.cls-44 {
    fill: url(#Degradado_sin_nombre_3-2);
}

.cls-45 {
    fill: #333;
}

.cls-46 {
    fill: #bed8e5;
}

.cls-47 {
    fill: #010143;
}

.cls-48 {
    fill: #e6e6e6;
}

.cls-49 {
    fill: #4d4d4d;
}

.cls-50 {
    fill: #f2f2f2;
}

.cls-51 {
    stroke: #4dcc9d;
    stroke-width: 0.29px;
}

.cls-51, .cls-54, .cls-99 {
    stroke-miterlimit: 10;
}

.cls-52 {
    fill: #4dcc9d;
}

.cls-54 {
    stroke: #000;
    stroke-width: 0.87px;
}

.cls-55 {
    fill: #ecb22e;
}

.cls-56 {
    fill: #666;
}

.cls-57 {
    fill: #3ab1d5;
}

.cls-58 {
    fill: #2a7f99;
}

.cls-59 {
    fill: #5e5ab1;
}

.cls-60 {
    fill: #43417e;
}

.cls-61 {
    fill: #d1a033;
}

.cls-62 {
    fill: #967325;
}

.cls-63 {
    fill: url(#Degradado_sin_nombre_33-3);
}

.cls-64 {
    fill: url(#Degradado_sin_nombre_33-4);
}

.cls-65 {
    fill: url(#Degradado_sin_nombre_47);
}

.cls-66 {
    clip-path: url(#clip-path-8);
}

.cls-68 {
    clip-path: url(#clip-path-9);
}

.cls-69 {
    fill: url(#Degradado_sin_nombre_19);
}

.cls-70 {
    fill: url(#Degradado_sin_nombre_35);
}

.cls-71 {
    fill: #3c2c17;
}

.cls-72 {
    fill: url(#Degradado_sin_nombre_85);
}

.cls-73 {
    fill: url(#Degradado_sin_nombre_19-2);
}

.cls-74 {
    fill: url(#Degradado_sin_nombre_35-2);
}

.cls-75 {
    fill: url(#Degradado_sin_nombre_85-2);
}

.cls-76 {
    fill: url(#Degradado_sin_nombre_9);
}

.cls-77 {
    fill: url(#Degradado_sin_nombre_33-5);
}

.cls-78 {
    fill: url(#Degradado_sin_nombre_79-3);
}

.cls-79 {
    clip-path: url(#clip-path-10);
}

.cls-80 {
    clip-path: url(#clip-path-11);
}

.cls-81 {
    fill: url(#Degradado_sin_nombre_63);
}

.cls-82 {
    fill: url(#Degradado_sin_nombre_9-2);
}

.cls-83 {
    clip-path: url(#clip-path-12);
}

.cls-84 {
    opacity: 0.19;
}

.cls-85 {
    fill: url(#Degradado_sin_nombre_68);
}

.cls-87 {
    fill: url(#Degradado_sin_nombre_63-2);
}

.cls-88 {
    fill: #002;
}

.cls-89 {
    fill: url(#Degradado_sin_nombre_33-6);
}

.cls-90 {
    fill: url(#Degradado_sin_nombre_52);
}

.cls-92 {
    fill: url(#Degradado_sin_nombre_21);
}

.cls-93 {
    fill: url(#Degradado_sin_nombre_26);
}

.cls-94 {
    clip-path: url(#clip-path-13);
}

.cls-95 {
    fill: #4b3b87;
}

.cls-96 {
    fill: #f4f1f3;
}

.cls-97 {
    fill: #331423;
}

.cls-98 {
    fill: #9b3e69;
}

.cls-99 {
    stroke: #fff;
    stroke-width: 0.48px;
}

.cls-100 {
    clip-path: url(#clip-path-14);
}

.cls-101 {
    fill: url(#Degradado_sin_nombre_44);
}

.cls-102 {
    fill: url(#Degradado_sin_nombre_21-2);
}

.cls-103 {
    fill: #5752b3;
}

.cls-104 {
    clip-path: url(#clip-path-15);
}

.cls-105 {
    fill: red;
}