@media only screen and (min-width: 320px) and (max-width: 550px) {
  .mainContainer {
    width: 500%;
    position: relative;
    left: 600px;
    transform: translateX(-50%);
  }
}

/* .mainContainer { */
/*     position: absolute; */
/*     width: 100%; */
/*     top: 25%; bottom: 25%; right: 0px; left: 0px; */
/* } */

#ball {
  cx: 0px;
  cy: 0px;
}

*[id$="Shadow"] {
  filter: blur(1.2px);
}

#blackPlayerAntennaShadow,
#bluePlayerAntennaShadow {
  filter: blur(5px);
}

#blackPlayerEyes,
#bluePlayerEyes {
  filter: drop-shadow(0px 0px 5px rgba(3, 255, 218, 1));
}

/*--------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------*/

#bluePlayerHead {
  transform-origin: 141px 223px;
}

#bluePlayerRacket {
  transform-origin: 150px 270px;
}
#bluePlayerHandLeft {
  transform-origin: 127px 244px;
}

#bluePlayerBody {
  transform-origin: 141px 231px;
}

#bluePlayerShadowGroup {
  transform-origin: 143px 311px;
}

#bluePlayerHit {
  stroke-dasharray: 0;
  transform-origin: 281px 188px;
  opacity: 0;
}

/*--------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------*/

#blackPlayerHead {
  transform-origin: 1299px 223px;
}

#blackPlayerRacket {
  transform-origin: 1290px 270px;
}
#blackPlayerHandLeft {
  transform-origin: 1313px 244px;
}

#blackPlayerBody {
  transform-origin: 1299px 200px;
}

#blackPlayerShadowGroup {
  transform-origin: 1297px 311px;
}

#blackPlayerHit {
  stroke-dasharray: 0;
  transform-origin: 1159px 188px;
  opacity: 0;
}
