@media only screen and (min-width: 320px) and (max-width: 767px) {
    .spaceBot{
        width: 150%;
        position: relative;
        left: 30%;
        transform: translateX(-50%);   
    }
}
.mantoInvisible {
  display: none;
}
.mantoGroup0 {
  fill: #f2f2f2;
}
.mantoGroup1 {
  fill: #ebeb00;
}
.mantoGroup2 {
  fill: #d80017;
}
.mantoGroup3 {
  fill: #98ce29;
}

.cuerpoDeAsta {
  fill: url(#gradienteDeCuerpoDeAsta);
}
.puntaDeAsta {
  fill: url(#gradienteDePuntaDeAsta);
}
.ganchillos {
  fill: #99b9c2;
  transform: translate(3px, 0);
}
.logoColor {
  fill: #002e81;
}

#laptop,
#codeScreenA,
#codeScreenB,
#codeScreenA2,
#codeScreenB2,
#desktop,
#leftHandOpenedFingers,
#rightHandClosedFingers,
#rightHandThumb,
#banderaGuia,
#axis {
  opacity: 0;
}

*[id$="Shadow"] {
  filter: blur(1.2px);
}

#rightAntennaShadow {
  filter: blur(5px);
}

#eyes {
  filter: drop-shadow(0px 0px 5px rgba(3, 255, 218, 1));
}

#unobotCavasBg {
  display: none;
}

.clss-1,
.clss-31,
.clss-32,
.clss-33,
.clss-34,
.clss-36,
.clss-37,
.clss-38 {
  fill: none;
}

.clss-14,
.clss-2,
.clss-22,
.clss-4 {
  isolation: isolate;
}

.clss-3,
.clss-39 {
  fill: #fff;
}

.clss-11,
.clss-4 {
  fill: #738b92;
}

.clss-10,
.clss-26,
.clss-35,
.clss-4,
.clss-43,
.clss-49 {
  mix-blend-mode: multiply;
}

.clss-4 {
  opacity: 0.37;
}

.clss-5 {
  fill: #010159;
}

.clss-6 {
  fill: #99b9c2;
}

.clss-7 {
  fill: url(#Degradado_sin_nombre_30);
}

.clss-8 {
  fill: url(#Degradado_sin_nombre_79);
}

.clss-9 {
  clip-path: url(#clip-path);
}

.clss-10 {
  opacity: 0.58;
}

.clss-12 {
  fill: #002e81;
}

.clss-13 {
  clip-path: url(#clip-path-2);
}

.clss-14 {
  opacity: 0.23;
}

.clss-15 {
  fill: #e7e9ea;
}

.clss-16 {
  fill: #010138;
}

.clss-17 {
  fill: url(#Degradado_sin_nombre_3);
}

.clss-18 {
  fill: #020286;
}

.clss-19 {
  fill: url(#Degradado_sin_nombre_5);
}

.clss-20 {
  clip-path: url(#clip-path-3);
}

.clss-21 {
  fill: url(#Degradado_sin_nombre_3-2);
}

.clss-22 {
  opacity: 0.17;
}

.clss-23 {
  fill: url(#Degradado_sin_nombre_29);
}

.clss-24,
.clss-27 {
  opacity: 0.3;
}

.clss-25 {
  clip-path: url(#clip-path-4);
}

.clss-26,
.clss-35,
.clss-43,
.clss-49 {
  opacity: 0.81;
}

.clss-26 {
  fill: url(#Degradado_sin_nombre_28);
}

.clss-27 {
  fill: #62b1fc;
}

.clss-28 {
  fill: #de3f42;
}

.clss-29 {
  fill: #f2cb2e;
}

.clss-30 {
  fill: #84d68b;
}

.clss-31,
.clss-36 {
  stroke: #79d992;
}

.clss-31,
.clss-32,
.clss-33,
.clss-34,
.clss-36,
.clss-37,
.clss-38 {
  stroke-miterlimit: 10;
}

.clss-31,
.clss-32,
.clss-33,
.clss-34 {
  stroke-width: 1.61px;
}

.clss-32,
.clss-37 {
  stroke: #df508f;
}

.clss-33,
.clss-38 {
  stroke: #8bfcff;
}

.clss-34 {
  stroke: #f4d430;
}

.clss-35 {
  fill: url(#Degradado_sin_nombre_28-2);
}

.clss-36,
.clss-37,
.clss-38 {
  stroke-width: 2.17px;
}

.clss-39 {
  mix-blend-mode: screen;
  opacity: 0.14;
}

.clss-40 {
  fill: #03ffda;
}

.clss-41 {
  fill: url(#Degradado_sin_nombre_3-3);
}

.clss-42 {
  fill: url(#Degradado_sin_nombre_2);
}

.clss-43 {
  fill: url(#Degradado_sin_nombre_28-3);
}

.clss-44 {
  fill: #edeef0;
}

.clss-45 {
  fill: #87868a;
}

.clss-46 {
  fill: #7f8484;
}

.clss-47 {
  fill: #aeadb3;
}

.clss-48 {
  fill: #b5bcbc;
}

.clss-49 {
  fill: url(#Degradado_sin_nombre_28-4);
}

.clss-50 {
  fill: #ff06c4;
}
